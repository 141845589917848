@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
:root {
  --primary-bg: rgb(55, 12, 110);
  --primary-color: rgb(187, 187, 187);
  --primary-font: 'Open Sans', sans-serif;
  --button-bg: rgb(25, 168, 25);
  --button-active-bg: rgb(22, 177, 22);
  --main-font: 'Comfortaa', cursive;
  --title-font: 'Lato', sans-serif;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  float: right;
  display: none;
}

::-webkit-scrollbar-track {
  background-color: rgba(32, 32, 32, 0.1);
  border-radius: 4px;
  border: 1px solid white;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(92, 92, 92);
  border-radius: 4px;
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: rgb(55, 12, 110);
  background-color: var(--primary-bg);
  color: rgb(187, 187, 187);
  color: var(--primary-color);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pane {
  position: relative;
  min-width: 280px;
  max-width: 400px;
  min-height: 450px;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 0px 20px rgb(19, 19, 19);
  overflow-y: auto;
}

.info {
  transform: translateY(240px);
  font-size: 12px;
}

#spot {
  width: 170px;
  margin: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: rgb(25, 168, 25);
  background-color: var(--button-bg);
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  font-weight: 400;
  transform: translateY(240px);
}

#spot:active, #spot:hover {
  background-color: rgb(22, 177, 22);
  background-color: var(--button-active-bg);
}

#main {
  margin: 0;
  padding: 0;
  font-family: 'Comfortaa', cursive;
  font-family: var(--main-font);
  font-size: 55px;
  text-align: center;
  color: rgb(25, 168, 25);
  color: var(--button-bg);
}

#msg {
  color: rgb(177, 177, 177);
  margin: 0;
  margin-top: 10px;
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  font-weight: 300;
  font-size: 13.5px;
}

#direct {
  position: absolute;
  bottom: 40px;
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 13.5px;
}

#direct p {
  cursor: pointer;
  margin-top: 5px;
}

#direct p:hover {
  color: rgb(22, 177, 22);
  color: var(--button-active-bg);
  transform: scale(1.1);
}

.mixed-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  #main {
    margin: 0;
    padding: 0;
    font-family: 'Comfortaa', cursive;
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    color: rgb(25, 168, 25);
    color: var(--button-bg);
  }

  #msg {
    color: rgb(177, 177, 177);
    margin: 0;
    margin-top: 5px;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-family: var(--primary-font);
    font-weight: 300;
    font-size: 13px;
  }

  .pane {
    position: relative;
    min-width: 280px;
    max-width: 400px;
    min-height: 450px;
    height: 96%;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgb(19, 19, 19);
  }
}

#mix {
  transform: translateY(200px);
}

#persona {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: black;
  border: grey 1px solid;
}

#topbar {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border: grey 1px solid;
}

#back {
  position: absolute;
  left: 15px;
  cursor: pointer;
}

#title {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-family: var(--title-font);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 3px;
  max-width: 45%;
  overflow: hidden;
}

#desc {
  position: absolute;
  right: 65px;
  cursor: pointer;
}

#exit {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

#round {
  box-shadow: 0px 0px 5px rgb(83, 83, 83);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 10px;
  overflow: hidden;
}

#round:hover {
  transform: scale(1.06);
}

#round img {
  cursor: pointer;
}

#user-info {
  margin-left: 5px;
}

#user-info h4, h6 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}

#user-info p {
  margin: 0;
  padding: 0;
  font-size: 12.5px;
  color: grey;
}

#progress-container {
  position: relative;
  margin: 10px;
  margin-bottom: 15px;
  width: 95%;
  height: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(172, 172, 172);
}

#progress-bar {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: rgba(170, 169, 169, 0.5);
  cursor: pointer;
}

#progress-tracker {
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  width: 50px;
  border-radius: 5px;
  background-color: rgb(177, 177, 177);
  cursor: pointer;
}

#seeker {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: rgb(22, 177, 22);
  background-color: var(--button-active-bg);
  cursor: pointer;
}

#collapse-playlist {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 10px;
  border-radius: 7px;
}

#expand-playlist {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 10px;
  border-radius: 7px;
  transform: rotate(180deg);
}

#playlist {
  position: relative;
  width: 92%;
  height: max(260px, 45%);
  border-radius: 12px;
  border: black 5px solid;
  padding: 2px;
  background-color: rgb(36, 202, 36);
  overflow-y: auto;
}

.track {
  position: relative;
  box-sizing: border-box;
  width: 99.8%;
  min-height: 80px;
  border-radius: 5px;
  background-color: black;
  border: black 1px solid;
  padding: 10px;
  margin-top: 3px;
}

.track:hover {
  border: grey 1px solid;
}

.track .title {
  max-width: 75%;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.track .artist {
  max-width: 75%;
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: grey;
}

.track .duration {
  max-width: 75%;
  margin: 0;
  padding: 0;
  font-size: 13px;
  color: rgb(153, 109, 15);
}

.track img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

#playbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 10px;
}

#playbar-empty {
  height: 70px;
  width: 90%;
}

#play-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 10px;
}

#play-btn, #pause-btn {
  margin-left: 5px;
}

#play-btn, #pause-btn, #prev-btn, #next-btn {
  cursor: pointer;
}

#play-btn:hover, #pause-btn:hover, #prev-btn:hover, #next-btn:hover {
  transform: scale(1.1);
}

#active-track {
  margin-top: 10px;
  color: rgb(235, 235, 235);
  font-size: 13px;
  max-width: 200px;
  word-wrap: none;
  white-space: nowrap;
  height: 40px;
  overflow: hidden;
}

#active-track p {
  margin: 0;
}

#bottom-tray {
  position: absolute;
  bottom: 0px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#bottom-tray img:hover {
  cursor: pointer;
}

#bottom-tray img:hover {
  transform: scale(1.1);
}

#modal {
  position: absolute;
  top: calc(50% - 120px);
  right: calc(50% - 120px);
  box-sizing: border-box;
  padding: 20px;
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgb(210, 214, 218);
  box-shadow: 0px 0px 25px rgb(77, 77, 77);
}

#modal h4 {
  color: rgb(53, 53, 53);
  margin: 5px;
}

#pdes {
  margin: 5px;
  color: rgb(77, 74, 74);
  font-size: 13px;
  text-align: center;
}

#pcount {
  margin: 5px;
  color: rgb(150, 108, 4);
  font-size: 13px;
  text-align: center;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.close:hover {
  transform: scale(1.05);
}

/*Search stuff*/

#search-bar-container {
  position: relative;
}

#search-bar {
  height: 39px;
  width: 300px;
  border-radius: 23px;
  background-color: rgb(36, 36, 36);
  color: rgb(233, 233, 233);
  font-size: 14px;
  margin: 20px;
  padding: 3px;
  padding-left: 50px;
  padding-right: 20px;
  border: none;
  outline: none;
}

#search-icon {
  position: absolute;
  left: 38px;
  top: 33px;
}

#build {
  width: 170px;
  margin: 10px;
  margin-top: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: rgb(25, 168, 25);
  background-color: var(--button-bg);
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  font-weight: 400;
}

#build:hover {
  background-color: rgb(22, 177, 22);
  background-color: var(--button-active-bg);
}

#filters {
  color: rgb(25, 168, 25);
  color: var(--button-bg);
  font-size: 13px;
}

#filters label {
  margin: 5px;
  margin-right: 20px;
  text-transform: capitalize;
}

#filters input {
  transform: translateY(3.5px);
  -webkit-appearance: none;
  background-color: rgb(36, 36, 36);
	border-radius: 2px;
  width: 12px;
  height: 12px;
  border: none;
  outline: none;
}

#filters input:checked {
	background-color: #b1b1b1;
}

#results {
  position: relative;
  width: 92%;
  height: max(380px, 60%);
  border-radius: 12px;
  border: black 5px solid;
  margin-top: 20px;
  padding: 2px;
  padding-top: 0;
  background-color: rgb(36, 36, 36);
  overflow-y: auto;
}

#lmix {
  position: absolute;
  right: 70px;
  cursor: pointer;
}

#notif {
  position: absolute;
  right: 60px;
  top: 15px;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  background-color: indigo;
  color: white;
  font-size: 7px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*Menu stuff*/

#menu-list {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  font-size: 15px;
}

#menu-list p {
  cursor: pointer;
  text-decoration: none;
  color: 'Lato', sans-serif;
  color: var(--title-font);
}

#menu-list p:hover {
  color: rgb(25, 168, 25);
  color: var(--button-bg);
  transition-delay: 100ms;
}

.mixes {
  box-sizing: border-box;
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  border: rgb(73, 73, 73) 1px solid;
  padding: 10px;
  overflow-x: auto;
}

#mix-description {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(21, 21, 21, 0.87);
  font-family: 'Lato', sans-serif;
}

#mix-description h4 {
  color: rgb(219, 219, 212);
  margin: 5px;
}

#mix-description p {
  margin: 5px;
  color: rgb(170, 170, 170);
  font-size: 13px;
  text-align: center;
}

#mix-controls {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  display: flex;
  border: rgb(73, 73, 73) 1px solid;
  padding: 10px;
  overflow-x: auto;
  justify-content: space-evenly;
}

/*#mix-controls button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: grey 1px solid;
  color: white;
  font-family: var(--primary-font);
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
}*/

.selected-casette {
  position: relative;
  height: 100%;
  min-width: 100%;
  width: 500px;
  background-color: rgb(36, 36, 36);
  border: rgb(143, 134, 11) 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin-right: 3px;
  z-index: 10;
}

.casette {
  position: relative;
  height: 100%;
  width: 60px;
  min-width: 60px;
  background-color: rgb(36, 36, 36);
  border: rgb(102, 102, 102) 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin-right: 3px;
}

.casette:hover, .selected-casette:hover {
  transform: scale(1.06);
}

.sidetape {
  height: 100%;
}

.widetape {
  width: 100%;
}

.body {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-form {
  margin-top: 20px;
  width: 90%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.sub-form input {
  box-sizing: border-box;
  height: 39px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(36, 36, 36);
  color: rgb(187, 170, 15);
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  outline: none;
}

.sub-form textarea {
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(36, 36, 36);
  color: rgb(167, 167, 167);
  font-size: 14.5px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  outline: none;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.sub-form label {
  color: rgb(180, 180, 180);
  font-size: 12px;
}

.img-container img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  cursor: move;
}

.img-container {
  position: relative;
  height: 200px;
  width: 325px;
  overflow: hidden;
}

#confirm-btn {
  position: absolute;
  right: 20px;
  top: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: grey 1px solid;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

#back-btn {
  position: absolute;
  left: 20px;
  top: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: grey 1px solid;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

#confirm-btn:hover, #back-btn:hover {
  transition-delay: 100ms;
  background-color: rgb(53, 35, 156);
  border:rgb(53, 35, 156) 1px solid;
}

.blank {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.blank img:hover {
  transform: scale(1.15);
}

#upload {
  position: relative;
  margin-top: 20px;
  height: 200px;
  width: 100%;
  border: gray 1px dashed;
  border-radius: 3px;
  overflow: hidden;
}

#upload-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: grey;
  font-size: 13px;
}

#upload-btn:active, #upload-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

#file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

.cfab {
  cursor: pointer;
  box-sizing: border-box;
  width: 90px;
  height: 90px;
  padding: 10px;
  border-radius: 15px;
  background-color: black;
  box-shadow: 0px 0px 10px black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: grey;
  font-size: 12px;
}

.cfab:hover, .dfab:hover {
  transform: scale(1.05);
}

.dfab {
  cursor: pointer;
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border-radius: 15px;
  background-color: black;
  box-shadow: 0px 0px 5px black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dfab img:hover {
  transform: none;
}

/* Tape */

.tape {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 325px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
}

.tape img {
  position: absolute;
}

#cover {
  border-radius: 10px;
}

#error {
  box-sizing: border-box;
  height: 40px;
  width: 90%;
  background-color: rgb(37, 37, 37);
  color: rgb(172, 169, 169);
  font-size: 13px;
  position: absolute;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  z-index: 200;
  box-shadow: 0px 0px 15px black;
}

/*Profile section*/
#roundel {
  width: 130px;
  height: 130px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: rgb(85, 85, 85) 7px solid;
  overflow: hidden;
}

#roundel:hover {
  border: rgb(121, 57, 240) 7px solid;
}

#roundel img {
  width: 130px;
  border-radius: 100px;
  height: auto;
  cursor: pointer;
}

.edit-btn {
  border: none;
  outline: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: rgb(89, 0, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgb(37, 37, 37);
}

.edit-btn:hover {
  transform: scale(1.1);
}

.profile-form {
  width: 90%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.profile-form input {
  box-sizing: border-box;
  height: 39px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(36, 36, 36);
  color: rgb(187, 170, 15);
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.profile-form textarea {
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  border-radius: 6px;
  background-color: rgb(36, 36, 36);
  color: rgb(167, 167, 167);
  font-size: 14.5px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  outline: none;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.profile-form label {
  color: rgb(180, 180, 180);
  font-size: 12px;
}

.mixGenres {
  position: relative;
  box-sizing: border-box;
  background-color: rgb(36, 36, 36);
  height: 80px;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  padding: 5px;
  border-radius: 6px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
}

.badge {
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 24px;
  padding: 0px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 3px;
  margin-left: 4px;
  margin-right: 4px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  font-size: 11px;
  border-radius: 5px;
  box-shadow: 0px 0px 12px rgb(10, 10, 10);
}

.badge:hover {
  transform: scale(1.06);
}

.fab {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  outline: none;
  cursor: pointer;
  padding-top: 5px;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background-color: rgb(18, 197, 18);
  box-shadow: 0px 0px 12px rgb(10, 10, 10);
  z-index: 10;
}

.fab:hover {
  transform: scale(1.08);
}

#panel {
  position: absolute;
  top: calc(50% - 200px);
  right: calc(5%);
  box-sizing: border-box;
  padding: 20px;
  width: 90%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(210, 214, 218);
  box-shadow: 0px 0px 25px rgb(19, 19, 19);
  z-index: 20;
  overflow-y: auto;
}

#genre-search-bar {
  height: 34px;
  width: 90%;
  border-radius: 23px;
  background-color: rgb(36, 36, 36);
  color: rgb(233, 233, 233);
  font-size: 13.5px;
  margin: 20px;
  padding: 3px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  outline: none;
  z-index: 20;
}

#genre-search-results {
  position: absolute;
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
  top: 114px;
  height: 200px;
  width: 80%;
  padding: 20px;
  background-color: rgb(36, 36, 36);
  overflow-y: auto;
  color: rgb(172, 172, 172);
}

#genre-search-results p {
  padding-top: 3px;
  font-size: 12.5px;
  cursor: pointer;
}

#genre-search-results p:hover {
  color: rgb(22, 177, 22);
  color: var(--button-active-bg);
}

.direction {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  color: rgb(224, 224, 224);
  border-radius: 4px;
}

#fdir {
  background-color: rgb(30, 139, 3); 
  position: absolute; 
  top: 0px;
  right: 20px;
  z-index: 30;
}

#bdir {
  background-color: rgb(158, 12, 12); 
  position: absolute;
  top: 0px; 
  left: 20px;
  z-index: 30;
}

/*=====================================================================*/

.sharing {
  box-sizing: border-box;
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 40px;
  background-color: rgb(36, 36, 36);
  color: rgb(172, 169, 169);
  font-family: 'Open Sans', sans-serif;
  font-family: var(--primary-font);
  font-size: 13px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  outline: none;
  word-wrap: none;
  white-space: nowrap;
}

.sharing p {
  margin: 0;
  padding: 0;
}

.sharing img {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.sharing img:hover {
  transform: scale(1.1);
}

/*Menu embed*/

#embed-bar {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border-radius: 7px;
  background-color: rgb(36, 36, 36);
  color: rgb(185, 142, 22);
  font-size: 13.5px;
  margin: 20px;
  margin-bottom: 0;
  padding: 3px;
  padding-left: 20px;
  padding-right: 50px;
  border: none;
  outline: none;
  z-index: 20;
}

#buildMix {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0;
  border-radius: 20px;
  background-color: rgb(25, 168, 25);
  background-color: var(--button-bg);
  color: rgb(240, 240, 240);
}

#buildMix:hover {
  background-color: rgb(22, 177, 22);
  background-color: var(--button-active-bg);
}

#embed {
  position: absolute;
  top: 80px;
  right: 30px;
  z-index: 40;
  cursor: pointer;
}

#embed:hover {
  transform: scale(1.06);
}

/*Playlists*/
.playlists {
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  border: rgb(73, 73, 73) 1px solid;
  padding: 10px;
  overflow-x: auto;
}

.playlists:nth-child(n) {
  z-index: 5*n;
}

.record {
  box-sizing: border-box;
  width: 120px;
  min-width: 120px;
  height: 100%;
  position: relative;
  background-color: rgb(36, 36, 36);
  border: rgb(102, 102, 102) 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin-right: -15px;
  padding: 10px;
  box-shadow: 0px 0px 20px black;
}

.record:hover {
  margin-right: 3px;
  transition-delay: 40ms;
}

.record h4 {
  font-size: 12px;
  color:rgb(219, 219, 212);
  margin: 5px;
  text-align: center;
  white-space: nowrap;
}

.record p {
  margin: 5px;
  color: rgb(170, 170, 170);
  font-size: 12px;
  text-align: center;
}

.record img {
  cursor: pointer;
  margin-top: 10px;
}

.record img:hover {
  transform: scale(1.1);
}


/*--------------------------------------------------------------------*/

#note {
  position: relative;
  font-size: 13px;
  height: 100%;
  width: 80%;
  padding: 10px;
  color:rgb(189, 189, 189);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#note li {
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgb(150, 108, 4);
}

#note input {
  box-sizing: border-box;
  padding: 5px 15px 5px 15px;
  width: 100%;
  height: 35px;
  border-radius: 20px;
  outline: none;
  border: rgb(150, 108, 4) 1px solid;
  background-color: rgb(37, 37, 37);
  color: grey;
}

#note label {
  font-size: 11px;
  color: rgb(112, 112, 112);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  margin-bottom: 15px;
}

#btn-holder {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#btn-holder button {
  height: 30px;
  width: 100px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn {  
  background-color: rgb(10, 133, 16);
  color: rgb(209, 209, 209);
}

.submit-btn:hover {
  transition-duration: 100ms;
  background-color: rgb(16, 153, 23);
}

.reset-btn {
  background-color: rgb(148, 13, 13);
  color: rgb(190, 190, 190);
}

.reset-btn:hover {
  transition-duration: 100ms;
  background-color: rgb(175, 22, 22);
}
